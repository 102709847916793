import androidSvg from "bundle-text:./assets/android.svg";
import iosSvg from "bundle-text:./assets/ios.svg";
import backSvg from "bundle-text:./assets/back.svg";
const isMobile = document.documentElement.clientWidth < 800;
const isWechat = /MicroMessenger/i.test(window.navigator.userAgent);

async function getData(url = "", data = {}) {
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache",
    headers: {
      "Content-Type": "application/json",
      Referer: "xiedaimala.com",
    },
  });
  return response.json();
}

const setWechatConfig = (signPackage) => {
  wx.config({
    appId: signPackage.appId,
    url: signPackage.url,
    timestamp: signPackage.timestamp,
    nonceStr: signPackage.nonceStr,
    signature: signPackage.signature,
    jsApiList: ["updateTimelineShareData", "updateAppMessageShareData"],
    openTagList: ["wx-open-launch-app"],
  });
  wx.ready(() => {
    wx.updateAppMessageShareData({
      title: "邀您试用饥人谷编程App",
      desc: "安卓端可下载安装包直接试用，ios端敬请期待",
      link: window.location.href,
      imgUrl:
        "https://static.xiedaimala.com/xdml/image/5939aa7c-d446-47c4-a9c1-ea1e52b10249/2021-9-13-14-22-7.png",
      success: () => { },
    });
    wx.updateTimelineShareData({
      title: "邀您试用饥人谷编程App",
      link: window.location.href,
      imgUrl:
        "https://static.xiedaimala.com/xdml/image/5939aa7c-d446-47c4-a9c1-ea1e52b10249/2021-9-13-14-22-7.png",
      success: () => { },
    });
  });
};

const attachDownloadButton = (key, params) => {
  const targetNode = document.querySelector(
    `#download-${key}-application-button`
  );
  const { version, packageLink } = params;
  const text = !!packageLink ? "立即获取" : "敬请期待";
  const svgMap = { android: androidSvg, ios: iosSvg };
  targetNode.href = packageLink || "javascript:void(0)";
  if (isMobile && packageLink) {
    targetNode.onclick = () => {
      toast(
        '即将开始下载<br/><span class="sub">若下载未发起，请在浏览器中打开此页面</span>'
      );
    };
  }

  targetNode.innerHTML = `
    <div class="row">
      ${svgMap[key]}<span class="text">${text}</span>
    </div>
    <span class="version">版本号: ${version}</span>
  `;
  targetNode.style.visibility = 'visible';
};

const appendIcon = () => {
  const targetNode = document.querySelector(".goback");
  targetNode.innerHTML = targetNode.innerHTML + backSvg;
};

document.addEventListener("DOMContentLoaded", () => {
  appendIcon();
  getData(`https://static.xiedaimala.com/jirengu-app/versions/ios.json?v=${Date.now()}`).then(
    (data) => {
      attachDownloadButton("ios", data);
    }
  );
  getData(
    `https://static.xiedaimala.com/jirengu-app/versions/android.json?v=${Date.now()}`
  ).then((data) => {
    attachDownloadButton("android", data);
  });
  if (window.location.hash === "#test-launch-app") {
    appendOpenInWechat();
  }
  if (isWechat) {
    getData(
      `https://xiedaimala.com/wechat_h5_activities/wechat_config?share_url=${window.location.href}`
    ).then((data) => {
      setWechatConfig(data.sign_package);
    });
  }
});

const appendOpenInWechat = () => {
  wx.checkJsApi({
    jsApiList: ["wx-open-launch-app"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
    success: function (res) {
      console.log("可用");
    },
    fail: (err) => {
      console.log(err, "不可用");
    },
  });
  const node = document.createElement("div");
  node.classList.add("open-in-wechat");
  const messageForApp = `{"actionType":"navToWebviewHref","href":"http://192.168.31.222:3000/courses/72142978-564e-4363-af01-4f2d596b80b2/random/12f0e10f89", "queryParams": {"hhh": "yes"}}`
  node.innerHTML = `
  <wx-open-launch-app
    id="wx-launch-btn"
    appid="wx216f81173053f8ce"
    extinfo='${messageForApp}'
  >
    <script type="text/wxtag-template">
      <style>.btn { padding: 12px 20px;border: none;outline: none;background: #f3a536;color: white;border-radius: 30px; box-shadow: 0 0 8px 4px rgba(243,165,54,0.2);}</style>
      <div style="padding: 8px;">
        <button class="btn">App内查看</button>
      </div>
    </script>
  </wx-open-launch-app>
  `;
  document.body.appendChild(node);
  let btn = document.getElementById("wx-launch-btn");
  btn.addEventListener("launch", function (e) {
    console.log("success");
  });
  btn.addEventListener("click", function (e) {
    executeCopy(messageForApp)
  });
  btn.addEventListener("error", function (e) {
    console.log("fail", e.detail);
    window.location.href = "https://xiedaimala.com/app/"
  });
};

const toast = (message) => {
  const toastNode = document.createElement("div");
  toastNode.innerHTML = message;
  toastNode.classList.add("toast-wrapper");
  document.body.appendChild(toastNode);
  setTimeout(() => {
    document.body.removeChild(toastNode);
  }, 3000);
};

const executeCopy = (value) => {
  const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('readonly', 'readonly');
    input.setAttribute('value', value);
    input.setSelectionRange(0, 9999);
    if (document.execCommand('copy')) {
      console.log('成功');
    }
    document.body.removeChild(input);
}

document.addEventListener('WeixinOpenTagsError', function (e) {
  console.log(e.detail.errMsg);
});
